/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "I’ve been delving into computer graphics and WebGL the past few months,\nas a way to solidify my knowledge I built an asteroids clone in JavaScript\nwith the rendering done in WebGL.\nTo deal with WebGL API boilerplate I used the twgl\n", React.createElement("a", {
    href: "http://twgljs.org/",
    target: "_blank"
  }, "library"), ". It also includes\nsome 4x4 matrix and vec3 helpers that are needed to handle movement and physics.\nThis was mainly a project to learn WebGL basics so I added some\nfeatures to confirm my understanding, like global scaling and loading a\n3d model of a plane from\n", React.createElement("a", {
    href: "https://www.turbosquid.com/3d-models/free-spowith-f1-camel-3d-model/516387",
    target: "_blank"
  }, "turbosquid"), "."), "\n", React.createElement(_components.p, null, "Learning GPU graphics programming pairs well with learning 3d modelling\nas so many concepts overlap, like cameras, matrix transforms, coordinate systems,\nlighting and shading, materials, et cetera. I picked up some very basic\nblender skills to create the single asteroid model and the geometric plane\nmodel.\nMost of what I learned about Blender was from these wonderful\n", React.createElement("a", {
    href: "http://gryllus.net/Blender/3D.html",
    target: "_blank"
  }, "lessons"), "."), "\n", React.createElement(_components.p, null, "Although the physics of asteroids is pretty minimal, it can still be daunting\nif you haven’t done it before. Luckily I found an amazing series on youtube\nby Keith Peters which can be found\n", React.createElement("a", {
    target: "_blank",
    href: "https://www.youtube.com/user/codingmath/videos?flow=list&view=0&sort=dd&live_view=500"
  }, "here"), ".\nHe covers a plethora of math concepts related to computer graphics and animation.\nI highly recommend them."), "\n", React.createElement(_components.p, null, "You may notice the collisions are a bit coarse, I am using axis aligned\nbounding boxes, as it is the simplest method to get something working, but\ndoesn’t provide the best results. As my focus for this project was on\nlearning I was fine with this tradeoff."), "\n", React.createElement(_components.p, null, "The playable game is below, it’s missing some polish to make it feel like\nan actual game, such as points and high scores and all that.\nJust the minimal set of features to be called asteroids."), "\n", React.createElement(_components.p, null, "You can find the source code on\n", React.createElement(_components.a, {
    href: "https://github.com/dvingo/webgl_asteroids"
  }, "GitHub")), "\n", React.createElement(_components.p, null, "Play the game ", React.createElement("a", {
    href: "/asteroids/index.html",
    target: "_blank"
  }, "here"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
